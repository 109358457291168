<template>
<div class="col-xl-6 configurator__total total">
  <div class="total__box">
    <div class="total-cost">
      <div class="total-cost__item flex-shrink-0">
        <div class="title-secondary bold-weight">Aktueller Preis:</div>
          <span class="text-ink">(ohne MwSt zzgl. Versandkosten)</span>
        </div>
        <div class="total-cost__item text-end">
          <div class="total-price total-price--discount">{{ $filters.currency(totalPrice) }}</div>
          <div class="total-price total-price--old mt-1">{{ $filters.currency(oldPrice) }}</div>
        </div>
      </div>
      <div class="сredit-info">
        <div>
          <i class="fas fa-info me-2 small-size"></i> oder <span class="text-danger">€135.32</span> pro Monat (für 48x Monate)
        </div>
        <a data-bs-toggle="modal" href="#creaditModal" class="link">Mehr information</a>
      </div>
      <div class="total__box-cart">
        <a class="btn btn-outline-primary" data-bs-toggle="modal" href="#requestModal">Unverbindliche Anfrage</a>
        <a href="#" class="btn btn-primary" @click="addToCart">In den Warenkorb</a>
      </div>
      <a href="#!" class="link-icon mt-4" @click="printed"><i class="fas fa-print"></i> Angebot drucken</a>
      <a href="#linkModal" class="link-icon mt-2" data-bs-toggle="modal"><i class="fas fa-save"></i> Konfiguration speichern</a>
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const price = computed(() => {
      return store.getters['configurator/price']
    })
    const totalPrice = computed(() => {
      return Math.ceil(price.value.total)
    })
    const oldPrice = computed(() => {
      if (typeof price.value.old_price !== 'undefined') {
        if (price.value.old_price === Math.ceil(price.value.total)) {
          return 0
        } else {
          return price.value.old_price
        }
      } else {
        return 0
      }
    })
    function addToCart () {
      const summary = store.getters['configurator/summary']
      store.dispatch('checkout/addItem', summary)
      // redirect to cart
      router.push({ name: 'cart' })
    }
    async function printed () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const summary = store.getters['configurator/summary']
      const p = document.getElementById('print-content')
      const config = btoa(JSON.stringify(summary))
      await axios.post(apiUrl + 'print/configurator', { re: apiKey, config: config }).then(resp => {
        if (resp.data) {
          p.innerHTML = resp.data
          setTimeout(() => {
            window.print()
          }, 2000)
        }
      })
    }
    return {
      totalPrice,
      oldPrice,
      addToCart,
      printed
    }
  }
}
</script>
