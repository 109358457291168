<template>
  <main class="content container">
    <InfoBoxLg v-if="width >= 992"/>
    <div class="title-main bold-weight mb-3">{{ title }}</div>
    <div class="configurator" :class="[ toggleSteps ? 'toggle-sidebar' : '']">
      <Loader v-if="loading" class="pb-5 pt-5"/>
      <div v-if="width >= 992" class="configurator__side flex-shrink-0">
        <div class="configurator__side-inner">
          <StepNavigation />
        </div>
      </div>
      <div v-if="!loading" class="configurator__content">
        <button class="btn-reset btn-toggle-sidebar" @click="hideSteps"></button>
        <div class="configurator__content-sticky">
          <PreviewImages />
          <CalcMetr />
          <div v-if="width <= 991" class="configurator__fast-access">
            <a class="btn btn-outline-primary" data-bs-toggle="modal" href="#requestModal">Unverbindliche Anfrage</a>
            <div class="text-end flex-grow-1">
              <div class="total-price total-price--discount">{{ $filters.currency(totalPrice) }}</div>
              <div class="total-price total-price--old mt-1">{{ $filters.currency(oldPrice) }}</div>
            </div>
          </div>
          <Carousel v-if="width >= 1200" styleClass="carousel slide configurator__carousel"/>
          <div v-if="width >= 1200" class="configurator__content-info">
            <div class="row">
              <ControlBox/>
              <PriceBox/>
            </div>
          </div>
        </div>
        <Carousel v-if="width < 1200" styleClass="carousel slide configurator__carousel"/>
        <div v-if="width < 1200" class="configurator__content-info">
          <div class="row">
            <ControlBox/>
            <PriceBox/>
          </div>
        </div>
        <StepNavigation v-if="width < 992" class="mb-5"/>
      </div>
    </div>
    <InfoBoxMd v-if="width < 992"/>
    <ContentDescription :description="configuratorDescription" />
  </main>
<RequestForm />
<CarouselModal />
<PreviewImageModal />
<CreditModal />
<LinkModal />
</template>
<script>
import { computed, ref } from 'vue'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import StepNavigation from '~/pages/configurator/stepNavigation'
import PreviewImages from '~/pages/configurator/common/previewImages'
import Carousel from '~/pages/configurator/common/carousel'
import ControlBox from '~/pages/configurator/common/controlBox'
import PriceBox from '~/pages/configurator/common/priceBox'
import InfoBoxLg from '~/pages/configurator/common/infoLg'
import InfoBoxMd from '~/pages/configurator/common/infoMd'
import RequestForm from '~/pages/configurator/common/requestForm'
import CarouselModal from '~/pages/configurator/common/carouselModal'
import PreviewImageModal from '~/pages/configurator/common/previewImageModal'
import CreditModal from '~/pages/configurator/common/creditModal'
import LinkModal from '~/pages/configurator/common/linkModal'
import ContentDescription from '~/pages/configurator/common/contentDescription'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import axios from 'axios'
import CalcMetr from '@/pages/configurator/common/CalcMetr.vue'

export default {
  components: {
    CalcMetr,
    StepNavigation,
    PreviewImages,
    Carousel,
    ControlBox,
    PriceBox,
    InfoBoxLg,
    RequestForm,
    CarouselModal,
    InfoBoxMd,
    PreviewImageModal,
    CreditModal,
    LinkModal,
    ContentDescription
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const toggleSteps = ref(false)
    const width = ref(window.innerWidth)
    onMounted(() => {
      getConfigurator()
      // checking resize
      window.onresize = () => {
        width.value = window.innerWidth
      }
    })
    // computed
    const loading = computed(() => {
      return store.getters['configurator/loading']
    })
    const title = computed(() => {
      return store.getters['configurator/name']
    })
    const price = computed(() => {
      return store.getters['configurator/price']
    })
    const configuratorDescription = computed(() => {
      return store.getters['configurator/configuratorDescription']
    })
    const totalPrice = computed(() => {
      return Math.ceil(price.value.total)
    })
    const oldPrice = computed(() => {
      if (typeof price.value.old_price !== 'undefined') {
        if (price.value.old_price === Math.ceil(price.value.total)) {
          return 0
        } else {
          return price.value.old_price
        }
      } else {
        return 0
      }
    })
    // methods
    function hideSteps () {
      toggleSteps.value = !toggleSteps.value
    }
    async function getConfigurator () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const config = (router.currentRoute.value.params && router.currentRoute.value.params.name) ? router.currentRoute.value.params.name : 'solaranlage-konfigurator'
      const { data } = await axios.post(apiUrl + 'configurator/domain', { re: apiKey, name: config })
      if (Object.keys(data).length > 0) {
        await store.dispatch('configurator/setConfigurator', data)
      } else {
        // 404
        await router.push({ name: 'notFound' })
      }
    }
    useMeta({
      title: title.value ?? process.env.VUE_APP_TITLE
    })
    return {
      hideSteps,
      loading,
      toggleSteps,
      width,
      title,
      totalPrice,
      oldPrice,
      configuratorDescription
    }
  }
}
</script>
